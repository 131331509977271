export const queriesPreviewFallback: Record<string, any> = {
  es: [
    {
      query: 'destacados',
      title: 'Los más destacados'
    },
    {
      query: 'perfumes',
      title: 'Perfumes para cualquier ocasión'
    },
    {
      query: 'cosmetica',
      title: 'Esenciales en tu rutina de skincare'
    },
    {
      query: 'cabello',
      title: 'Para el mejor cuidado capilar'
    }
  ],
  fr: [
    {
      query: 'parfum',
      title: 'Les meilleurs parfums'
    },
    {
      query: 'coffret',
      title: 'Coffrets incontournables'
    },
    {
      query: 'maquillage',
      title: 'Pour un look impeccable'
    },
    {
      query: 'cheveux',
      title: 'Prenez soin de vos cheveux'
    }
  ],
  pt: [
    {
      query: 'Perfumes',
      title: 'Deixa-te envolver pelo aroma'
    },
    {
      query: 'Cabelo',
      title: 'Cabelo saudável '
    },
    {
      query: 'Cosmética',
      title: 'Dedica-te o cuidado que mereces'
    },
    {
      query: 'Maquilhagem',
      title: 'Realça a tua beleza'
    }
  ]
};
