import { endpointAdapterFactory, schemaMapperFactory } from '@empathyco/x-adapter';

export const criteoEndpointAdapter = endpointAdapterFactory({
  endpoint: 'https://d.eu.criteo.com/delivery/retailmedia',
  defaultRequestOptions: {
    id: 'criteo'
  },
  requestMapper: schemaMapperFactory<any, any>({
    'criteo-partner-id': 'criteoPartnerId',
    'retailer-visitor-id': 'retailerVisitorId',
    'page-id': 'pageId',
    'event-type': () => 'viewSearchResult',
    keywords: 'query',
    filters: ({ selectedFilters }) => {
      const filters = (selectedFilters as Record<string, string>[])
        .filter(({ facetId }) => ['facetBrand'].includes(facetId))
        .map(filter => filter.id.replace('facetBrand:', ''));
      return filters.length > 0 ? `(brand,in,${filters.join(',')})` : null;
    }
  }),
  responseMapper: schemaMapperFactory<any, any>({
    sponsoredProducts: 'placements'
  })
});
