










  import {
    SnippetCallbacks,
    SnippetConfig,
    XEventsTypes,
    XOn,
    XProvide
  } from '@empathyco/x-components';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { Component, Inject, Provide, Vue, Watch } from 'vue-property-decorator';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Result } from '@empathyco/x-types';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';
  import { queriesPreviewFallback } from './x-components/queries-preview.options';

  @Component({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    }
  })
  export default class App extends Vue {
    protected isOpen = false;

    //TODO: Remove console.log and console.error
    protected async sendBeaconUrl(url: string): Promise<void> {
      if (url) {
        try {
          await fetch(`https://${url}`);
        } catch (error) {
          console.error(`Error sending beacon: ${url}`, error);
        }
      }
    }

    @XOn([
      'OnLoadBeacon',
      'OnClickBeacon',
      'OnWishlistBeacon',
      'OnViewBeacon',
      'OnProductLoadBeacon',
      'OnViewProductBeacon',
      'OnSponsoredLoadBeacon',
      'OnSponsoredViewBeacon'
    ])
    sendBeacon(url: string): void {
      this.sendBeaconUrl(url);
    }

    @XOn(['UserClickedResultAddToCart'])
    sendBasketChangeBeacon(result: Result): void {
      if (result.onBasketChangeBeacon) {
        this.sendBeaconUrl(result.onBasketChangeBeacon);
      }
    }

    @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      this.isOpen = true;
    }

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;
    protected device = useDevice();

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    @Provide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @XProvide('queriesPreviewInfo')
    public get queriesPreviewInfo(): QueryPreviewInfo[] | undefined {
      return (
        //safeguard as the customer is not informing queriesPreview
        this.snippetConfig.queriesPreview && this.snippetConfig.queriesPreview?.length > 0
          ? this.snippetConfig.queriesPreview
          : queriesPreviewFallback[this.snippetConfig.lang] ?? queriesPreviewFallback['fr']
      );
    }

    @XOn(['SnippetCallbackExecuted'])
    async callbackExecuted(payload: XEventsTypes['SnippetCallbackExecuted']): Promise<void> {
      if ('UserClickedResultAddToCart' === payload.event) {
        const addedToCart = (await payload.callbackReturn) as { id: string; addedToCart: boolean };
        this.$x.emit('UserModifiedAdd2cart', {
          ...addedToCart
        });
      }
    }

    public excludedExtraParams = [
      'callbacks',
      'uiLang',
      'consent',
      'documentDirection',
      'currency',
      'filters',
      'isolate',
      'cart',
      'wishlist',
      'productId',
      'queriesPreview',
      'criteoPartnerId',
      'retailerVisitorId',
      'userAgent'
    ];

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }

    @Watch('snippetConfig.cart')
    syncCart(cart: number): void {
      this.$x.emit('CartNumberUpdated', cart);
    }

    @Watch('device.deviceName')
    syncDevice(deviceName: string): void {
      this.$setLocaleDevice(deviceName);
    }
  }
