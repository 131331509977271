import {
  FacetConfig,
  facetSchema,
  getFacetConfig,
  platformAdapter,
  PlatformFacet,
  PlatformFacetType,
  PlatformRecommendationsRequest,
  PlatformSemanticQueriesRequest,
  recommendationsRequestSchema,
  resultSchema,
  semanticQueriesRequestSchema
} from '@empathyco/x-adapter-platform';
import {
  NumberRangeFacet,
  RecommendationsRequest,
  SemanticQueriesRequest
} from '@empathyco/x-types';
import { createMutableSchema, MapperContext } from '@empathyco/x-adapter';
import { criteoEndpointAdapter } from './criteo/criteo.endpoint-adapter';

export const adapter = platformAdapter;

declare module '@empathyco/x-types' {
  export interface Result {
    hasStock: boolean;
    brand: string;
    discountPct: string;
    hideDiscountPrice: boolean;
    hexadecimalCode: string;
    variantValue: string;
    variantAttributeId: number;
    variantOptionId: number;
    productId: string;
    isSponsored: boolean;
    RenderingAttributes: string;
    onClickBeacon: string;
    onBasketChangeBeacon: string;
    onWishlistBeacon: string;
    onLoadBeacon: string;
    onViewBeacon: string;
    onSponsoredLoadBeacon: string;
    onSponsoredViewBeacon: string;
    isFlagshipBannerProduct: boolean;
    countReviews: number;
    numberVariants: number;
    linkApp: string;
  }

  export interface ResultVariant {
    salePrice: number;
    imageLink: string;
    originalPrice: number;
    availability: boolean;
    brand: string;
    link: string;
    linkApp: string;
    variantAttributeId: number;
    variantOptionId: number;
    hideDiscountPrice: boolean;
  }
}

const variantsSchema = createMutableSchema<any, any>({
  originalPrice: 'price',
  salePrice: 'salePrice',
  variantValue: 'variantValue',
  hexadecimalCode: 'hexadecimalCode',
  imageLink: 'imageLink',
  discountPct: 'discountPct',
  availability: ({ availability }) => availability !== 'false',
  brand: 'brand',
  link: 'link',
  variantAttributeId: 'variantAttributeId',
  variantOptionId: 'variantOptionId',
  linkApp: 'linkApp'
});

resultSchema.$override<any, any>({
  hasStock: ({ availability }) => availability !== 'false',
  brand: 'brand',
  rating: 'rating',
  discountPct: 'discountPct',
  hideDiscountPrice: 'hideDiscountPrice',
  hexadecimalCode: 'hexadecimalCode',
  variantAttributeId: 'variantAttributeId',
  variantOptionId: 'variantOptionId',
  productId: 'productId',
  variants: {
    $path: 'variants',
    $subSchema: variantsSchema as any
  },
  variantValue: 'variantValue',
  isSponsored: (_, $context) => ($context?.isSponsored as boolean) ?? false,
  price: {
    value: ({ __prices }) => __prices?.current?.value,
    originalValue: ({ __prices }) => __prices?.previous?.value ?? __prices?.current.value,
    futureValue: ({ __prices }) => __prices?.future?.value ?? __prices?.current.value,
    hasDiscount: ({ __prices }) =>
      __prices?.current.value < (__prices?.previous?.value ?? __prices?.current.value)
  },
  countReviews: 'countReviews',
  numberVariants: 'numberVariants',
  linkApp: rawResult => (rawResult.variants ? rawResult.variants[0].linkApp : rawResult.linkApp)
});

recommendationsRequestSchema.$override<
  RecommendationsRequest,
  Partial<PlatformRecommendationsRequest>
>({
  // TODO Top clicked demo endpoint breaks if it receives the scope parameter
  extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams
});

semanticQueriesRequestSchema.$override<
  SemanticQueriesRequest,
  Partial<PlatformSemanticQueriesRequest>
>({
  extraParams: ({ extraParams }) => {
    return {
      extraParams,
      filter_ids: 'NOT_ALL_WORDS,NOT_PARTIAL'
    };
  }
});

platformAdapter.criteo = criteoEndpointAdapter;

const getFacetConfigWithEditable = (facetId: string, type: PlatformFacetType): FacetConfig => {
  if (facetId === 'price') {
    const salePriceConfig = getFacetConfig('editable-range');
    const getSalePriceFilterId = (context?: MapperContext): string | undefined =>
      (context?.requestParameters?.filter as string[])?.find(filter => filter.includes('price'));

    salePriceConfig.schema = {
      ...salePriceConfig.schema,
      id: ({ filter }, $context) => {
        const currentSalePriceFilterId = getSalePriceFilterId($context);

        return currentSalePriceFilterId ?? filter;
      },
      label: (_, $context) => getSalePriceFilterId($context)?.split(':')[1],
      range: ({ value }, $context) => {
        const currentSalePriceFilterId = getSalePriceFilterId($context);

        const stringRage = currentSalePriceFilterId?.split(':')[1] ?? (value as string);

        const min = Number(stringRage.split('-')[0]);
        const max = Number(stringRage.split('-')[1]);

        return {
          min: Number.isNaN(min) ? null : min,
          max: Number.isNaN(max) ? null : max
        };
      }
    };

    return salePriceConfig;
  }

  return getFacetConfig(type);
};

facetSchema.$override<PlatformFacet, Partial<NumberRangeFacet>>({
  modelName: ({ type, facet }) => getFacetConfigWithEditable(facet, type).modelName as any,
  filters: {
    $path: 'values',
    $subSchema: ({ type, facet }) => getFacetConfigWithEditable(facet, type).schema
  }
});
