import { DefaultPDPAddToCartService, PDPAddToCartService } from '@empathyco/x-components/tagging';
import { Result } from '@empathyco/x-types';
import { DefaultSessionService } from '@empathyco/x-utils';

/**
 * Primor implementation for the {@link PDPAddToCartService}.
 *
 * @public
 */
export class PrimorPDPAddToCartService extends DefaultPDPAddToCartService {
  public static instance: PDPAddToCartService = new PrimorPDPAddToCartService();

  public constructor() {
    super();
  }

  /**
   * Stores in the local storage the information from the Result clicked by the user
   * in order to be able to track later on. The Result stored is enhanced with
   * the session for the checkout tagging event.
   *
   * @param result - The result to store.
   *
   * @public
   */
  storeResultClicked(result: Result): void {
    const key = result[this.clickedResultStorageKey as keyof Result] as string;
    const storageId = this.getStorageId(key);
    const session = DefaultSessionService.instance.getSessionId();
    if (result.tagging?.checkout?.params) {
      result.tagging.checkout.params.session = session;
    }
    if (storageId) {
      this.localStorageService.setItem(storageId, result, this.clickedResultStorageTTLMs);
    }
  }

  /**
   * Checks if the local storage contains a result information for the given id and moves
   * the result info from the local storage to the session storage without deleting it from
   * the local storage.
   *
   * @param id - The id of the result to move to the session storage.
   *
   * @public
   */
  moveToSessionStorage(id?: string): void {
    const storageId = this.getStorageId(id);
    if (storageId) {
      const result = this.localStorageService.getItem(storageId);
      if (result) {
        this.sessionStorageService.setItem(storageId, result);
      }
    }
  }
}
